import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './ui/Layout';
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import QRCodePage from "./pages/QRCodePage";
import './App.css';

function App() {
  return (
    <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/qrcode" element={<QRCodePage />} />
          </Routes>
        </Layout>
    </Router>
  );
}

export default App;
