import React, { useState } from "react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Drawer, Divider, List, ListItem } from '@mui/material';

interface SidenavProps {
    children?: ReactNode;
    isOpen?: boolean;
    handleDrawerToggle: () => void;
}

const drawerWidth = 240;

const Sidenav: React.FC<SidenavProps> = ({ children, isOpen, handleDrawerToggle }) => {

    return (
        <div className="flex">
            <Drawer
                open={isOpen}
                onClose={handleDrawerToggle}
                style={{ width: drawerWidth, flexShrink: 0 }}
                PaperProps={{ style: { width: drawerWidth } }}>
                    <List>
                        <ListItem className="font-roboto-slab text-lg text-primary-dark drop-shadow-lg">
                            <Link to="/" onClick={handleDrawerToggle}>Home</Link>
                        </ListItem>
                        <Divider component="li" />
                        <ListItem className="font-roboto-slab text-lg text-primary-dark drop-shadow-lg">
                            <Link to="/contact" onClick={handleDrawerToggle}>Contact</Link>
                        </ListItem>
                        <Divider component="li" />
                    </List>
                </Drawer>
        </div>
    );
};

export default Sidenav;