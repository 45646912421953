import React from "react";
import { ReactNode } from "react";

interface FooterProps {
    children?: ReactNode;
}

const Footer: React.FC<FooterProps> = ({ children }) => {
    return (
        <div className="min-h-[56px] max-h-[56px] z-10 w-full flex self-end bg-primary rounded-b 
            mobile:min-h-[36px] mobile:max-h-[56px] mobile:rounded-none mobile:justify-center
            tablet-portrait:min-h-[56px] tablet-portrait:rounded-none
            tablet-landscape:min-h-[56px] tablet-landscape:rounded-none
            desktop-xl:min-h-[56px]
            desktop-xxl:min-h-[56px]
            desktop-xxxl:min-h-[56px]">
            <div className="flex flex-col justify-self-center self-center pl-12 font-roboto-slab text-xs text-primary-dark drop-shadow-lg
                mobile:pl-0
            ">
                © 2024 FormulaForward All rights reserved.
            </div>
        </div>
    );
};

export default Footer;