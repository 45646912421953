import React, {useState} from "react";

type ContactFormFields = {
    name: string;
    emailAddress: string;
    phoneNumber: string;
}

const ContactPage: React.FC = () => {
    const [fields, setFields] = useState<ContactFormFields>({
        name: "",
        emailAddress: "",
        phoneNumber: "",
    });

    const [errors, setErrors] = useState<ContactFormFields>({
        name: "",
        emailAddress: "",
        phoneNumber: "",
    });

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const validate = (): boolean => {
        let tempErrors: ContactFormFields = { name: "", emailAddress: "", phoneNumber: "" };
        let isValid = true;

        if (!fields.name) {
            tempErrors.name = "Name is required.";
            isValid = false;
        }

        if (!fields.emailAddress) {
            tempErrors.emailAddress = "Email address is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(fields.emailAddress)) {
            tempErrors.emailAddress = "Email address is invalid.";
            isValid = false;
        }

        if (!fields.phoneNumber) {
            tempErrors.phoneNumber = "Phone number is required.";
            isValid = false;
        } else if (!/^\d{10}$/.test(fields.phoneNumber)) {
            tempErrors.phoneNumber = "Phone number is invalid, (10 digits) required.";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (validate()) {
            console.log("Valid form");
            // Handle form submission here

            try {
                const response = await fetch('https://us-central1-formulaforward-app.cloudfunctions.net/submitContactForm', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(fields),
                });


                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                console.log(`Response data: ${responseData}`);
                // Handle success, e.g. show a success message
                setIsSubmitted(true);
            } catch (error) {
                console.error(`Error: ${error}`);
                // Handle error, e.g. show an error message
            }

        } else {
            console.log("Invalid form");
            // Handle form validation failure
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setFields({
            ...fields,
            [name]: value,
        });
    };

    return (
        <div className="w-full h-full flex flex-col content-center justify-center bg-secondary-linear overflow-y-auto">
            {isSubmitted && <div className="flex flex-col justify-self-center self-center font-roboto-slab text-lg text-primary-dark drop-shadow-lg">We will reach out to you soon!</div>}
            {!isSubmitted && (
                <form onSubmit={handleSubmit} className="w-3/6 h-fit pb-6 mt-6 mb-6 flex flex-col justify-self-center self-center rounded-lg
                    mobile:w-full mobile:h-full mobile:mt-0 mobile:mb-0 mobile:pb-6
                    tablet-portrait:w-4/6 tablet-portrait:mt-0 tablet-portrait:bg-white-75
                    tablet-landscape:mt-12 tablet-landscape:bg-white-75
                    desktop-xl:w-3/6 desktop-xl:bg-white-75
                    desktop-xxl:w-2/6 desktop-xxl:bg-white-75
                    desktop-xxxl:w-2/6 desktop-xxxl:bg-white-75">
                    <div className="pt-12 px-12
                        mobile:px-8
                        tablet-portrait:px-0">
                        <label htmlFor="name" className="w-full flex pb-4 font-roboto-slab text-lg text-primary-dark drop-shadow-lg
                            tablet-portrait:max-w-[75%] tablet-portrait:m-auto
                            desktop-xl:max-w-[75%] desktop-xl:m-auto
                            desktop-xxl:max-w-[75%] desktop-xxl:m-auto
                            desktop-xxxl:max-w-[75%] desktop-xxxl:m-auto">
                            Name
                        </label>
                        <input className="w-full h-12 flex bg-white-25 border-2 rounded-lg border-primary-dark-25 shadow-contact-input-inner
                            tablet-portrait:max-w-[75%] tablet-portrait:m-auto
                            desktop-xl:max-w-[75%] desktop-xl:m-auto
                            desktop-xxl:max-w-[75%] desktop-xxl:m-auto
                            desktop-xxxl:max-w-[75%] desktop-xxxl:m-auto" 
                            type="text"
                            id="name"
                            name="name"
                            value={fields.name}
                            onChange={handleChange}
                        />
                        {errors.name && 
                            <div className="pt-4 text-red-500 font-roboto-slab text-xs">
                                {errors.name}
                            </div>
                        }
                    </div>
                    <div className="pt-12 px-12
                        mobile:px-8
                        tablet-portrait:px-0">
                        <label htmlFor="emailAddress" className="w-full flex pb-4 font-roboto-slab text-lg text-primary-dark drop-shadow-lg
                            tablet-portrait:max-w-[75%] tablet-portrait:m-auto
                            desktop-xl:max-w-[75%] desktop-xl:m-auto
                            desktop-xxl:max-w-[75%] desktop-xxl:m-auto
                            desktop-xxxl:max-w-[75%] desktop-xxxl:m-auto">
                            Email address
                        </label>
                        <input className="w-full h-12 flex bg-white-25 border-2 rounded-lg border-primary-dark-25 shadow-contact-input-inner
                            tablet-portrait:max-w-[75%] tablet-portrait:m-auto
                            desktop-xl:max-w-[75%] desktop-xl:m-auto
                            desktop-xxl:max-w-[75%] desktop-xxl:m-auto
                            desktop-xxxl:max-w-[75%] desktop-xxxl:m-auto" 
                            type="email"
                            id="emailAddress"
                            name="emailAddress"
                            value={fields.emailAddress}
                            onChange={handleChange}
                        />
                        {errors.emailAddress && 
                            <div className="pt-4 text-red-500 font-roboto-slab text-xs">
                                {errors.emailAddress}
                            </div>
                        }
                    </div>
                    <div className="pt-12 px-12
                        mobile:px-8
                        tablet-portrait:px-0
                    ">
                        <label htmlFor="phoneNumber" className="w-full flex pb-4 font-roboto-slab text-lg text-primary-dark drop-shadow-lg
                            tablet-portrait:max-w-[75%] tablet-portrait:m-auto
                            desktop-xl:max-w-[75%] desktop-xl:m-auto
                            desktop-xxl:max-w-[75%] desktop-xxl:m-auto
                            desktop-xxxl:max-w-[75%] desktop-xxxl:m-auto">
                            Phone number
                        </label>
                        <input className="w-full h-12 flex bg-white-25 border-2 rounded-lg border-primary-dark-25 shadow-contact-input-inner
                            tablet-portrait:max-w-[75%] tablet-portrait:m-auto
                            desktop-xl:max-w-[75%] desktop-xl:m-auto
                            desktop-xxl:max-w-[75%] desktop-xxl:m-auto
                            desktop-xxxl:max-w-[75%] desktop-xxxl:m-auto" 
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={fields.phoneNumber}
                            onChange={handleChange}
                        />
                        {errors.phoneNumber && 
                            <div className="pt-4 text-red-500 font-roboto-slab text-xs">
                                {errors.phoneNumber}
                            </div>
                        }
                    </div>
                    <button type="submit" className="h-10 w-40 mt-6 justify-self-center self-center rounded-lg bg-primary-linear font-roboto-slab text-lg text-primary-dark drop-shadow-lg">
                        Contact Us
                    </button>
                </form>
            )}
        </div>
    );
};

export default ContactPage;