import React from "react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface HeaderProps {
    children?: ReactNode;
    handleDrawerToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ children, handleDrawerToggle }) => {

    return (
        <div className="z-10 min-h-[56px] max-h-[56px] w-full flex flex-row justify-between bg-white shadow-header rounded-t 
            mobile:min-h-[36px] mobile:max-h-[36px] mobile:rounded-none
            tablet-portrait:min-h-[56px] tablet-portrait:rounded-none
            tablet-landscape:min-h-[56px] tablet-landscape:rounded-none">
            <a href="https://formulaforward.finance/" className="min-h-[56px] max-w-[370px] ml-8 flex-auto self-center justify-self-start bg-[url('ff-logo-370x49.png')] bg-no-repeat
                mobile:h-9 mobile:max-w-[244px] mobile:m-auto mobile:bg-[url('ff-logo-244x33.png')] mobile:justify-self-start mobile:self-start"></a>
                
            <div className="flex flex-row">
                <div className="justify-self-end self-center pr-12 font-roboto-slab text-lg text-primary-dark drop-shadow-lg
                    mobile:pr-6">
                    <IconButton onClick={handleDrawerToggle}>
                        <MenuIcon className="text-primary-dark" />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default Header;