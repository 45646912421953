import React from "react";
import { Link } from "react-router-dom";

const HomePage: React.FC = () => {
    return (
        <div className="w-full h-full flex flex-col overflow-y-auto">
            <div className="h-[30%] flex flex-col justify-center items-start bg-[url('ff-city-v2-768x223.png')] bg-no-repeat bg-cover bg-center
                mobile:pt-6 mobile:pb-6"
                style={{paddingLeft: 'calc(10%)'}}>
                <div className="font-roboto-slab text-lg mobile:text-sm text-primary-dark drop-shadow-lg">
                    Welcome to <b>FormulaForward</b>,
                </div>
                <div className="font-roboto-slab text-lg mobile:text-sm text-primary-dark drop-shadow-lg">
                    your trusted partner in financial
                </div>
                <div className="font-roboto-slab text-lg mobile:text-sm text-primary-dark drop-shadow-lg">
                    and business growth solutions.
                </div>
            </div>
            <div className="h-[35%] flex flex-row justify-between items-center bg-secondary-linear
                mobile:flex-col"
                style={{ paddingLeft: 'calc(10%)', paddingRight: 'calc(10%)'}}>
                <div className="mt-2 mobile:mt-4 mb-2 font-roboto-slab text-lg mobile:text-sm text-white drop-shadow-lg">Bookkeeping</div>
                <div className="mb-2 font-roboto-slab text-lg mobile:text-sm text-white drop-shadow-lg flex flex-col text-center">
                    <div>Expense Tracking &</div>
                    <div>Bank Reconciliation</div>
                </div>
                <div className="mb-2 font-roboto-slab text-lg mobile:text-sm text-white drop-shadow-lg flex flex-col text-center">
                    <div>Invoicing &</div>
                    <div>Receivables</div>
                </div>
                <div className="mb-2 mobile:mb-4 font-roboto-slab text-lg mobile:text-sm text-white drop-shadow-lg">Payroll Processing</div>
            </div>
            <div className="h-[35%] px-12 flex justify-between content-center bg-white
                mobile:flex-col mobile:px-0 mobile:pt-3"
                style={{ paddingLeft: 'calc(10%)', paddingRight: 'calc(10%)'}}>
                <div className="flex flex-col justify-self-start self-center font-roboto-slab text-sm text-primary-dark drop-shadow-lg
                    mobile:text-center mobile:text-xs">
                    <div className="mobile:pt-3 mobile:mx-1">Empower your business with our expert <b>Advisory Services</b>.</div>
                    <div className="mobile:pt-1 mobile:mx-1">We provide personalized guidance and strategic advice,</div>
                    <div className="mobile:pt-1 mobile:mx-1">helping you navigate business challenges, identify growth opportunities,</div>
                    <div className="mobile:pt-1 mobile:mx-1">and make informed decisions that drive your business forward.</div>
                </div>
                <div className="flex flex-col justify-self-center self-center text-center">
                    <div className="font-roboto-slab text-lg text-primary-dark drop-shadow-lg
                        mobile:text-sm mobile:pt-6">
                        <div>Ready to formulate</div>
                        <div>the path forward?</div>
                    </div>
                    <div className="pt-4 
                        mobile:mt-6 mobile:mb-9">
                        <Link to="/contact">
                            <button className="h-10 w-40 rounded-lg bg-primary-linear font-roboto-slab text-lg text-primary-dark drop-shadow-lg">
                                Contact Us
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;