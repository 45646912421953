import React, { useState } from "react";
import { ReactNode } from "react";

import Header from "./Header";
import Sidenav from "./Sidenav";
import Footer from "./Footer";

interface LayoutProps {
    children?: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const [sidenavIsOpen, setSidenavIsOpen] = useState(false);

    const handleDrawerToggle = () => {
        setSidenavIsOpen(!sidenavIsOpen);
    }

    return (
        <div className="w-dvw h-dvh flex justify-items-center items-center bg-primary-dark bg-primary-radial">
            <main className="w-full h-full ml-auto mr-auto mt-0 mb-0 flex flex-col justify-between content-between bg-white shadow-3xl rounded
                mobile:ml-0 mobile:mr-0 mobile:mt-0 mobile:mb-0
                tablet-portrait:mt-0 tablet-portrait:mb-0 tablet-portrait:ml-0 tablet-portrait:mr-0
                tablet-landscape:mt-0 tablet-landscape:mb-0 tablet-landscape:ml-0 tablet-landscape:mr-0
                desktop-xl:w-[90%] desktop-xl:h-[90%]
                desktop-xxl:w-[90%] desktop-xxl:h-[90%]
                desktop-xxxl:w-[90%] desktop-xxxl:h-[90%]">
                <Header handleDrawerToggle={handleDrawerToggle} />
                <Sidenav isOpen={sidenavIsOpen} handleDrawerToggle={handleDrawerToggle} />
                <div className="flex w-full h-full overflow-hidden">{children}</div>
                <Footer />
            </main>
        </div>
    );
};

export default Layout;