import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const QRCodePage: React.FC = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const shouldRedirect = true;

        if (shouldRedirect) {
            navigate("/");
        }
    }, [navigate]);

    return (
        <div>QR Code Page</div>
    )
};

export default QRCodePage;